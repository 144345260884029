import React, { useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Top from "./Top";
import Bottom from "./Bottom";
import createBarrel from "../images/crateBarrel.png";
import cashApp from "../images/cashApp.png";
import { useSearchParams } from "react-router-dom";

const Container = styled.div`
  margin-bottom: 0px;
  text-align: center;
  @media (max-width: 700px) {
    display: block;
  }
`;

const StyledHeader = styled.h3`
  letter-spacing: 10px;
  font-size: 30px;
  margin-top: 0px;
  padding-top: 100px;
  @media (max-width: 700px) {
    font-size: 15px;
    margin-bottom: 20px;
    padding-top: 80px;
  }
`;

const RegistryText = styled.div`
  margin: 0 70px;
  text-align: center;
  @media (max-width: 700px) {
    margin: 0 20px;
  }
`;
const MainPhotoContainer = styled.div`
  display: flex;
  align-items;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
`;

const PhotoContainer = styled.div`
  border: 1px solid black;
  border-radius: 10px;
  margin: 20px;
  transition: transform 0.2s;
  padding: 5px 5px 0px 5px;

  &:hover {
    transform: scale(1.1);
  }
`;

const MainPicture = styled.img`
  width: 150px;
  height: 150px;
  object-fit: cover;
  @media (max-width: 700px) {
    width: 80px;
    height: 80px;
  }
`;
const Registry: React.FC = () => {
  const { t } = useTranslation();
  const handleClick = (url: string) => {
    window.open(url, "_blank") || window.location.replace(url);
  };
  const { i18n } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const lang = searchParams.get("lang");

  useEffect(() => {
    if (!lang) {
      searchParams.set("lang", i18n.language);
      setSearchParams(searchParams, { replace: true });
    } else if (lang === "en") {
      i18n.changeLanguage("en");
    } else {
      i18n.changeLanguage("es");
    }
  }, [lang]);

  return (
    <div>
      <Top />
      <Container>
        <StyledHeader>
          {t("tilesOfSections.registryList").toUpperCase()}
        </StyledHeader>
        <RegistryText id="registry">{t("registry.body")}</RegistryText>
        <MainPhotoContainer>
          <PhotoContainer
            onClick={() =>
              handleClick(
                "https://www.crateandbarrel.com/gift-registry/fabiola-villalpando-and-arjun-tanguturi/r7034519"
              )
            }
          >
            <MainPicture src={createBarrel} alt="header" />
          </PhotoContainer>
          <PhotoContainer
            onClick={() => handleClick("https://cash.app/$tanarj")}
          >
            <MainPicture src={cashApp} alt="header" />
          </PhotoContainer>
        </MainPhotoContainer>
      </Container>

      <Bottom />
    </div>
  );
};

export default Registry;
