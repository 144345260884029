import React, { useEffect } from "react";
import "./App.css";
import Heading from "./components/Heading";
import Top from "./components/Top";
import HomePage from "./components/HomePage";
import Bottom from "./components/Bottom";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

const App: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { i18n } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const lang = searchParams.get("lang");

  useEffect(() => {
    if (!lang) {
      searchParams.set("lang", i18n.language);
      setSearchParams(searchParams, { replace: true });
    } else if (lang === "en" || lang === "en-US") {
      i18n.changeLanguage("en");
    } else {
      i18n.changeLanguage("es");
    }
  }, [lang]);

  return (
    <div className="App">
      <Top />
      <Heading />
      <HomePage />
      <Bottom />
    </div>
  );
};

export default App;
