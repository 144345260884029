import React, { useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Top from "./Top";
import Bottom from "./Bottom";
import { useSearchParams } from "react-router-dom";

const Container = styled.div`
  margin: 0 20px;
  text-align: center;
`;

const StyledHeader = styled.h3`
  letter-spacing: 10px;
  font-size: 30px;
  margin-top: 0px;
  padding-top: 100px;
  @media (max-width: 700px) {
    font-size: 15px;
    margin-bottom: 20px;
    padding-top: 80px;
  }
`;

const TextContainerQuestion = styled.div`
  font-family: Snell Roundhand, cursive;
  text-align: center;
  margin-bottom: 20px;
  font-size: 40px;
  @media (max-width: 700px) {
    font-size: 25px;
    margin-bottom: 20px;
  }
`;
const TextContainerAnswer = styled(TextContainerQuestion)`
  font-family: "Montserrat";
  font-size: 30px;
  @media (max-width: 700px) {
    font-size: 15px;
    margin: 20px 0;
  }
`;

const HotelContainer = styled.div`
  margin: 20px 0;
`;

const TextContainerAnswerHotelName = styled(TextContainerAnswer)`
  margin-bottom: 0;
  font-weight: bold;
  @media (max-width: 700px) {
    margin: 10px 0 0 0;
  }
`;

const TextForWeddingDate = styled(TextContainerAnswerHotelName)`
  margin-bottom: 20px;
`;

const TextContainerAnswerHotelAddress = styled(TextContainerAnswer)`
  margin-bottom: 0;
  @media (max-width: 700px) {
    margin: 0 0 0 0;
  }
`;

const TextContainerAnswerHotelWebsite = styled.a`
  font-family: "Montserrat";
  text-align: center;
  margin-bottom: 20px;
  font-size: 30px;
  text-decoration: none;
  color: black;
  padding: 5px;
  @media (max-width: 700px) {
    font-size: 15px;
    margin: 10px 0 20px;
  }
  &:hover {
    color: #5f8575;
  }
`;

const FAQ: React.FC = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const lang = searchParams.get("lang");

  useEffect(() => {
    if (!lang) {
      searchParams.set("lang", i18n.language);
      setSearchParams(searchParams, { replace: true });
    } else if (lang === "en") {
      i18n.changeLanguage("en");
    } else {
      i18n.changeLanguage("es");
    }
  }, [lang]);
  return (
    <div>
      <Top />
      <Container>
        <StyledHeader>{t("tilesOfSections.faq").toUpperCase()}</StyledHeader>
        <TextContainerQuestion>{t("faq.dressCodeQ")}</TextContainerQuestion>
        <TextForWeddingDate>
          {t("weddingDayDetails.mehendiDate")}
        </TextForWeddingDate>
        <TextContainerAnswer>
          <b>{t("faq.dressCodeAttire")}</b>
          {t("faq.dressCodeA")}
        </TextContainerAnswer>
        <TextContainerAnswer>
          <b>{t("faq.dressCodeWomen")}</b>
          {t("faq.dressCodeA2")}
        </TextContainerAnswer>
        <TextContainerAnswer>
          <b>{t("faq.dressCodeMen")}</b>
          {t("faq.dressCodeA3")}
        </TextContainerAnswer>
        <TextForWeddingDate>
          {t("weddingDayDetails.indianCeremonyDate")}
        </TextForWeddingDate>
        <TextContainerAnswer>
          <b>{t("faq.dressCodeAttire")}</b>
          {t("faq.dressCodeA4")}
        </TextContainerAnswer>
        <TextContainerAnswer>
          <b>{t("faq.dressCodeWomen")}</b>
          {t("faq.dressCodeA5")}
        </TextContainerAnswer>
        <TextContainerAnswer>
          <b>{t("faq.dressCodeMen")}</b>
          {t("faq.dressCodeA6")}
        </TextContainerAnswer>
        <TextContainerAnswer>{t("faq.dressCodeA8")}</TextContainerAnswer>
        <TextForWeddingDate>
          {t("weddingDayDetails.weddingCeremonyDate")}
        </TextForWeddingDate>
        <TextContainerAnswer>
          <b>{t("faq.dressCodeAttire")}</b>
          {t("faq.dressCodeA7")}
        </TextContainerAnswer>
        <TextContainerAnswer>
          <b>{t("faq.dressCodeWomen")}</b>
          {t("faq.dressCodeA9")}
        </TextContainerAnswer>
        <TextContainerAnswer>
          <b>{t("faq.dressCodeMen")}</b>
          {t("faq.dressCodeA10")}
        </TextContainerAnswer>
        <TextContainerQuestion>
          {t("faq.dietaryRestrictionsQ")}
        </TextContainerQuestion>
        <TextContainerAnswer>
          {t("faq.dietaryRestrictionsA")}
        </TextContainerAnswer>
        <TextContainerQuestion>{t("faq.kidsQ")}</TextContainerQuestion>
        <TextContainerAnswer>{t("faq.kidsA")}</TextContainerAnswer>
        <TextContainerQuestion>{t("faq.plusOneQ")}</TextContainerQuestion>
        <TextContainerAnswer>{t("faq.plusOneA")}</TextContainerAnswer>
        <TextContainerQuestion>{t("faq.parkingQ")}</TextContainerQuestion>
        <TextContainerAnswer>{t("faq.parkingA")}</TextContainerAnswer>
        <TextContainerQuestion>{t("faq.outOfTownQ")}</TextContainerQuestion>
        <TextContainerAnswer>{t("faq.outOfTownA")}</TextContainerAnswer>
        <HotelContainer>
          <TextContainerAnswerHotelName>
            {"Hilton Chicago"}
          </TextContainerAnswerHotelName>
          <TextContainerAnswerHotelAddress>
            {"720 S Michigan Ave, Chicago, IL 60605"}
          </TextContainerAnswerHotelAddress>
          <TextContainerAnswerHotelAddress>
            {"+1 (312) 922-4400"}
          </TextContainerAnswerHotelAddress>
          <TextContainerAnswerHotelWebsite
            target="_blank"
            href="https://www.hilton.com/en/hotels/chichhh-hilton-chicago/?SEO_id=GMB-AMER-HH-CHICHHH&y_source=1_NzIxNzU2LTcxNS1sb2NhdGlvbi53ZWJzaXRl"
          >
            {t("faq.outOfTownHotelWebsite")}
          </TextContainerAnswerHotelWebsite>
        </HotelContainer>
        <TextContainerAnswer>{t("faq.outOfTownA2")}</TextContainerAnswer>
      </Container>
      <Bottom />
    </div>
  );
};

export default FAQ;
