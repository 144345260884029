import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  margin-bottom: 40px;
  text-align: center;
  @media (max-width: 700px) {
    margin-bottom: 0;
  }
`;
const StyledHeader = styled.h1`
  font-family: Snell Roundhand, cursive;
  font-size: 70px;
  line-height: 150%;
  @media (max-width: 700px) {
    font-size: 45px;
  }
`;

const WeddingDate = styled.h3`
  letter-spacing: 10px;
  font-size: 30px;
  margin-top: 0px;
  padding-top: 80px;
  @media (max-width: 700px) {
    font-size: 15px;
    margin-bottom: 20px;
    padding-top: 80px;
  }
`;

const Heading: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <WeddingDate>03 - 05 {t("names.month").toUpperCase()} 2024</WeddingDate>
      <StyledHeader> {t("names.fabiolaAndArjun")}</StyledHeader>
    </Container>
  );
};

export default Heading;
