import * as React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import * as ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import "./i18n";
import { Suspense } from "react";
import RSVPPage from "./pages/RSVPPage";
import FAQ from "./components/FAQ";
import WeddingDetails from "./components/WeddingDetails";
import Story from "./components/Story";
import Registry from "./components/Registry";

ReactDOM.render(
  <div>
    <BrowserRouter>
      <Suspense>
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="story" element={<Story />} />
          <Route path="rsvp" element={<RSVPPage />} />
          <Route path="faq" element={<FAQ />} />
          <Route path="wedding-timeline" element={<WeddingDetails />} />
          <Route path="registry" element={<Registry />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  </div>,
  document.getElementById("root")
);
