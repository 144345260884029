import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button, MenuItem, TextField } from "@mui/material";
import Top from "../components/Top";
import Bottom from "../components/Bottom";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

const Container = styled.div`
  display: flex;
  margin-bottom: 40px;
  text-align: center;
  flex-direction: column;

  @media (max-width: 700px) {
    display: block;
  }
`;

const HelperText = styled(Container)`
  margin-bottom: 0px;
`;

const StyledHeader = styled.h3`
  display: flex;
  justify-content: center;
  letter-spacing: 10px;
  font-size: 30px;
  margin-top: 0px;
  padding-top: 100px;
  @media (max-width: 700px) {
    font-size: 15px;
    margin-bottom: 20px;
    padding-top: 80px;
  }
`;

const RSVPContainer = styled.div`
  width: 90%;
  margin: auto;
`;

const RsvpCard = styled.div`
  border: 1px solid #5f8575;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 20px;
`;

const PasswordContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 20% 0;
`;

const GuestButtonContainer = styled.div`
  display: flex;
  justify-content: start;
`;

const StyledButton = styled.button`
  color: #5f8575;
  background-color: white;
  border: none;
  padding: 12px;
  border: 1px solid #5f8575;
  border-radius: 7px;
  &:hover {
    background-color: #e6f0e7;
  }
`;

const StyledTextField = styled(TextField)`
  & label.Mui-focused {
    color: #5f8575;
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: #5f8575;
    }
  }
`;

const RSVPPage: React.FC = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const lang = searchParams.get("lang");

  useEffect(() => {
    if (!lang) {
      searchParams.set("lang", i18n.language);
      setSearchParams(searchParams, { replace: true });
    } else if (lang === "en") {
      i18n.changeLanguage("en");
    } else {
      i18n.changeLanguage("es");
    }
  }, [lang]);
  const RSVPanswers = [
    {
      value: t("rsvp.yes"),
    },
    {
      value: t("rsvp.no"),
    },
  ];

  const entreAnswers = [
    {
      value: t("rsvp.beef"),
    },
    {
      value: t("rsvp.chicken"),
    },
    {
      value: t("rsvp.vegetarian"),
    },
  ];

  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [pwdValue, setPwdValue] = useState("");
  const initialCardInfomation = [
    {
      name: "",
      email: "",
      attendingMehendi: "No",
      attendingHindu: "No",
      attendingReception: "No",
      entree: "Vegetarian",
      dietaryRestrictions: "",
    },
  ];
  const [cardInformation, setCardInformation] = useState(initialCardInfomation);
  const checkPw = () => {
    // gets the current input value
    if (pwdValue === "Milo") {
      setIsVerified(true);
    } else {
      alert("Sorry, that's not it");
    }
  };

  const handleOnChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setPwdValue(event.target.value);
  };
  const handleOnClick = () => {
    setIsButtonClicked(true);
  };

  const handleGuestOnClick = () => {
    setCardInformation([
      ...cardInformation,
      {
        name: "",
        email: "",
        attendingMehendi: "No",
        attendingHindu: "No",
        attendingReception: "No",
        entree: "Vegetarian",
        dietaryRestrictions: "",
      },
    ]);
  };

  // returns disabled boolean value. if true than disable
  const handleValidEmail = () => {
    for (let i = 0; i < cardInformation.length; i++) {
      if (
        !cardInformation[i].email.includes("@") ||
        !cardInformation[i].email.includes(".")
      ) {
        return true;
      }
    }
    return false;
  };

  const handleOnChangeValidEmail = () => {
    for (let i = 0; i < cardInformation.length; i++) {
      if (
        !cardInformation[i].email.includes("@") ||
        !cardInformation[i].email.includes(".")
      ) {
        if (cardInformation[i].email !== "") {
          return true;
        }
      }
    }
    return false;
  };

  const DomContent = [];
  for (let i = 0; i < cardInformation.length; i++) {
    DomContent.push(
      <>
        {!isButtonClicked && (
          <RsvpCard key={i.toString()}>
            <GuestButtonContainer>
              {cardInformation.length - 1 === i && (
                <Button
                  variant="outlined"
                  style={{
                    marginBottom: "20px",
                    padding: "5px",
                    fontSize: "10px",
                  }}
                  sx={{
                    borderColor: "red",
                    color: "red",
                    ":hover": {
                      borderColor: "red",
                      background: "#FFCCCB",
                    },
                  }}
                  onClick={() => {
                    setCardInformation((cardInformation) =>
                      cardInformation.filter((_, idx) => idx !== i)
                    );
                  }}
                >
                  x
                </Button>
              )}
            </GuestButtonContainer>
            <TextField
              fullWidth
              id="name"
              label={t("rsvp.name")}
              variant="outlined"
              style={{ marginBottom: 10 }}
              onChange={(e) => {
                const tmp = [...cardInformation];
                tmp[i].name = e.target.value;
                setCardInformation(tmp);
              }}
            />
            <TextField
              required={true}
              fullWidth
              id="email"
              error={handleOnChangeValidEmail()}
              helperText={
                handleOnChangeValidEmail() ? "Please enter a valid email" : ""
              }
              label={t("rsvp.email")}
              variant="outlined"
              style={{ marginBottom: 20 }}
              onChange={(e) => {
                const tmp = [...cardInformation];
                tmp[i].email = e.target.value;
                setCardInformation(tmp);
              }}
            />
            <TextField
              id="attending"
              select
              label={t("rsvp.attendingMehendi")}
              helperText={t("rsvp.attendingMehendiQuestion")}
              sx={{
                marginBottom: 2,
                width: "80%",
                "& .MuiFormHelperText-root": {
                  textAlign: "center",
                },
              }}
              onChange={(e) => {
                const tmp = [...cardInformation];
                tmp[i].attendingMehendi = e.target.value;
                setCardInformation(tmp);
              }}
            >
              {RSVPanswers.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.value}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              id="attending"
              select
              label={t("rsvp.attendingHindu")}
              helperText={t("rsvp.attendingHinduQuestion")}
              sx={{
                marginBottom: 2,
                width: "80%",
                "& .MuiFormHelperText-root": {
                  textAlign: "center",
                },
              }}
              onChange={(e) => {
                const tmp = [...cardInformation];
                tmp[i].attendingHindu = e.target.value;
                setCardInformation(tmp);
              }}
            >
              {RSVPanswers.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.value}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              id="attending"
              select
              label={t("rsvp.attending")}
              helperText={t("rsvp.attendingQuestion")}
              sx={{
                marginBottom: 2,
                width: "80%",
                "& .MuiFormHelperText-root": {
                  textAlign: "center",
                },
              }}
              onChange={(e) => {
                const tmp = [...cardInformation];
                tmp[i].attendingReception = e.target.value;
                setCardInformation(tmp);
              }}
            >
              {RSVPanswers.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.value}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              fullWidth
              id="diet"
              label={t("rsvp.diet")}
              variant="outlined"
              style={{ marginBottom: 10 }}
              onChange={(e) => {
                const tmp = [...cardInformation];
                tmp[i].dietaryRestrictions = e.target.value;
                setCardInformation(tmp);
              }}
            />
            <TextField
              fullWidth
              id="entree"
              select
              label={t("rsvp.entreeChoice")}
              onChange={(e) => {
                const tmp = [...cardInformation];
                tmp[i].entree = e.target.value;
                setCardInformation(tmp);
              }}
            >
              {entreAnswers.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.value}
                </MenuItem>
              ))}
            </TextField>
          </RsvpCard>
        )}
      </>
    );
  }
  return (
    <div>
      <Top />
      <>
        {isVerified ? (
          <div>
            <Container>
              <StyledHeader>
                {t("tilesOfSections.rSVPSection").toUpperCase()}
              </StyledHeader>
              <RSVPContainer>
                {DomContent}
                {!isButtonClicked && (
                  <GuestButtonContainer>
                    <Button
                      variant="outlined"
                      style={{ margin: "10px 0", width: "30%", padding: "7px" }}
                      sx={{
                        borderColor: "#5F8575",
                        color: "#5F8575",
                        ":hover": {
                          borderColor: "#5F8575",
                          background: "#e6f0e7",
                        },
                      }}
                      onClick={handleGuestOnClick}
                    >
                      {t("rsvp.addGuest")}
                    </Button>
                  </GuestButtonContainer>
                )}
                <Button
                  type="submit"
                  variant="outlined"
                  disabled={handleValidEmail() || isButtonClicked}
                  color={"primary"}
                  style={{ margin: "20px", width: "50%", padding: "13px" }}
                  sx={{
                    borderColor: "#5F8575",
                    color: "#5F8575",
                    ":hover": {
                      borderColor: "#5F8575",
                      background: "#e6f0e7",
                    },
                  }}
                  onClick={async () => {
                    handleOnClick();
                    const formsResponseURL = `https://docs.google.com/forms/u/0/d/e/1FAIpQLSe_1JPkyyD_eEo5IxtlvIs4Qe_ouhz8dTZyVx_t-fnctCH6rw/formResponse`;
                    const url = new URL(formsResponseURL);
                    for (let i = 0; i < cardInformation.length; i++) {
                      const entreeChoice = () => {
                        if(cardInformation[i].entree === "Beef" || cardInformation[i].entree === "Carne de Res"){
                          return "Beef"
                        }
                        if(cardInformation[i].entree === "Chicken" || cardInformation[i].entree === "Pollo"){
                          return "Chicken"
                        }
                        if(cardInformation[i].entree === "Vegetarian" || cardInformation[i].entree === "Vegetariano"){
                          return "Vegetarian"
                        }
                        return "Vegetarian"
                      }
                      url.searchParams.set(
                        "entry.1774315341",
                        cardInformation[i].name
                      );
                      url.searchParams.set(
                        "emailAddress",
                        cardInformation[i].email
                      );
                      url.searchParams.set(
                        "entry.751378042",
                        cardInformation[i].attendingMehendi === "Yes" ||
                          cardInformation[i].attendingMehendi === "Si"
                          ? "Yes"
                          : "No"
                      );
                      url.searchParams.set(
                        "entry.1473236960",
                        cardInformation[i].attendingHindu === "Yes" ||
                          cardInformation[i].attendingHindu === "Si"
                          ? "Yes"
                          : "No"
                      );
                      url.searchParams.set(
                        "entry.1185182584",
                        cardInformation[i].attendingReception === "Yes" ||
                          cardInformation[i].attendingReception === "Si"
                          ? "Yes"
                          : "No"
                      );
                      url.searchParams.set(
                        "entry.390616756",
                        cardInformation[i].dietaryRestrictions
                      );
                      url.searchParams.set(
                        "entry.1736513055",
                        entreeChoice()
                      );
                      const response = await fetch(url, {
                        method: "POST",
                        headers: {
                          "Content-Type":
                            "application/x-www-form-urlencoded; charset=UTF-8",
                        },
                        mode: "no-cors",
                      });
                      try {
                        const response2 = await fetch(
                          "https://even-aloe-424404-p4.uc.r.appspot.com/",
                          {
                            method: "POST",
                            headers: {
                              "Content-Type": "application/json",
                            },
                            body: JSON.stringify({
                              to: cardInformation[i].email,
                              name: cardInformation[i].name,
                              email: cardInformation[i].email,
                              attending_mehendi:
                                cardInformation[i].attendingMehendi,
                              attending_hindu:
                                cardInformation[i].attendingHindu,
                              attending_reception:
                                cardInformation[i].attendingReception,
                              dietary: cardInformation[i].dietaryRestrictions,
                              entree: cardInformation[i].entree,
                            }),
                            mode: "cors",
                          }
                        );
                      } catch (e) {
                        console.log(e);
                      }
                    }
                  }}
                >
                  {isButtonClicked
                    ? t("rsvp.responseSubmitted")
                    : t("rsvp.submitResponse")}
                </Button>
                {isButtonClicked && (
                  <HelperText>
                    {t("rsvp.responseSubmittedHelperText")}
                  </HelperText>
                )}
              </RSVPContainer>
            </Container>
            <Bottom />
          </div>
        ) : (
          <>
            <StyledHeader>
              {t("tilesOfSections.rSVPSection").toUpperCase()}
            </StyledHeader>
            <PasswordContainer>
              <form onSubmit={checkPw}>
                <ButtonContainer>
                  <StyledTextField
                    onChange={handleOnChange}
                    label={t("rsvp.password")}
                    style={{ marginBottom: 20 }}
                    id="password"
                    name="password"
                  />
                  <StyledButton>
                    {t("rsvp.passwordButton").toUpperCase()}
                  </StyledButton>
                </ButtonContainer>
              </form>
            </PasswordContainer>
          </>
        )}
      </>
    </div>
  );
};

export default RSVPPage;
