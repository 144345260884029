import React, { useEffect, useState } from "react";
import styled from "styled-components";
import MenuItem from "@mui/material/MenuItem";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import MoreVert from "@mui/icons-material/MoreVert";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Dialog } from "@mui/material";

const Container = styled.div`
  display: flex;
  width: 100%;
  position: fixed;
  left: 0;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  padding: 10px 0;
  background: #fafafa;
  @media (max-width: 700px) {
    padding: 10px 0;
  }
`;

const MenuLinks = styled.a`
  text-decoration: none;
  font-family: "Optima", "sans-serif";
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-right: 10px;
  padding: 10px;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #5f8575;
  border-radius: 7px;
  &:hover {
    background-color: #e6f0e7;
  }
`;

const StyledLink = styled.a`
  text-transform: none !important;
  cursor: pointer;
  font-size: 25px;
  font-weight: bold;
  color: #5f8575;
  @media (max-width: 700px) {
    font-size: 15px;
  }
`;

const StyledMenuLinks = styled(MenuLinks)`
  width: 100%;
  border-radius: 10px;
  border: 1px solid #5f8575;
  padding: 4px;
  transition: transform 0.2s;
  &:hover {
    border: none;
    color: #5f8575;
  }
`;

const Top: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const lang = searchParams.get("lang");

  const english = "haga clic aquí para español 🇲🇽";
  const spanish = "click here for english 🇺🇸";
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [buttonText, setButtonText] = useState(spanish);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (lang === "en") {
      setButtonText(english);
    } else if (lang === "es") {
      setButtonText(spanish);
    }
  }, [lang]);

  const handleClose = () => {
    setOpen(false);
  };

  const navigate = useNavigate();
  const handleClick = (url: string) => {
    navigate(url);
  };

  return (
    <Container>
      <MenuRoundedIcon
        onClick={() => {
          setOpen(!open);
        }}
        style={{
          borderRadius: "6px",
          marginLeft: "20px",
          fontSize: "xx-large",
          color: "#5F8575",
          border: "1px solid #5F8575",
          padding: "2px",
        }}
      >
        <MoreVert />
      </MenuRoundedIcon>
      <Dialog
        onClose={handleClose}
        open={open}
        PaperProps={{
          style: { width: "80%", borderRadius: "6px" },
        }}
        sx={{
          "& .MuiDialog-container": {
            justifyContent: "flex-start",
            alignItems: "baseline",
          },
        }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
          }}
        >
          <StyledMenuLinks onClick={() => handleClick("/")}>
            {t("tilesOfSections.home")}
          </StyledMenuLinks>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
          }}
        >
          <StyledMenuLinks onClick={() => handleClick("/story")}>
            {t("tilesOfSections.ourStory")}
          </StyledMenuLinks>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
          }}
        >
          <StyledMenuLinks onClick={() => handleClick("/wedding-timeline")}>
            {t("tilesOfSections.theWedding")}
          </StyledMenuLinks>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
          }}
        >
          <StyledMenuLinks onClick={() => handleClick("/registry")}>
            {t("tilesOfSections.registryList")}
          </StyledMenuLinks>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
          }}
        >
          <StyledMenuLinks onClick={() => handleClick("/faq")}>
            {t("tilesOfSections.faq")}
          </StyledMenuLinks>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
          }}
        >
          <StyledMenuLinks onClick={() => handleClick("/rsvp")}>
            {t("tilesOfSections.rSVPSection")}
          </StyledMenuLinks>
        </MenuItem>
      </Dialog>
      <ButtonContainer>
        <StyledLink
          onClick={() => {
            let intendedLanguage;
            if (buttonText === english) {
              intendedLanguage = "es";
            } else {
              intendedLanguage = "en";
            }
            searchParams.set("lang", intendedLanguage);
            setSearchParams(searchParams, { replace: true });
          }}
        >
          {buttonText}
        </StyledLink>
      </ButtonContainer>
    </Container>
  );
};

export default Top;
