import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Top from "./Top";
import Bottom from "./Bottom";
import { useSearchParams } from "react-router-dom";

const StyledHeader = styled.h3`
  text-align: center;
  letter-spacing: 10px;
  font-size: 30px;
  margin-bottom: 20px;
  margin-top: 0px;
  padding-top: 100px;
  @media (max-width: 700px) {
    font-size: 15px;
    margin-bottom: 20px;
    padding-top: 80px;
  }
`;

const Timeline = styled.div`
  @media (max-width: 700px) {
    margin: 0 30px;

    &::after {
      left: 0%;
    }
  }
`;
const TimelineItem = styled.div`
  margin-bottom: 50px;
  @media (max-width: 700px) {
    &::after {
      left: 0%;
    }
  }
`;

const TimelineSubItem = styled.div`
  margin-bottom: 20px;
  @media (max-width: 700px) {
    &::after {
      left: 0%;
    }
  }
`;

const TimelineContentLeft = styled.div`
  display: flex;
  justify-content: center;
  margin-left: 50%;
  @media (max-width: 700px) {
    justify-content: flex-start;
    padding: 0 20px;
    margin-left: 0%;
  }
`;

const TimelineContentRight = styled.div`
  display: flex;
  justify-content: center;
  margin-right: 50%;
  @media (max-width: 700px) {
    padding: 0 20px;
    margin-right: 0%;
    justify-content: flex-start;
  }
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
`;

const WeddingTitle = styled.p`
  font-family: Snell Roundhand, cursive;
  margin: 20px 0 10px 0;
  font-size: 30px;
`;

const WeddingSubTitle = styled.p`
  font-family: Snell Roundhand, cursive;
  margin: 0 0 10px 0;
  font-size: 30px;
`;

const WeddingDate = styled.p`
  margin: 0;
  font-weight: bold;
`;

const WeddingLocation = styled.p`
  margin: 10px 0 5px 0;
`;

const WeddingSubLocation = styled.p`
  margin: 0 0 20px 0;
`;

const WeddingAddress = styled.p`
  font-family: Snell Roundhand, cursive;
  margin: 0 0 0 0;
  font-size: 14px;
`;
const WeddingAddress2 = styled.p`
  font-family: Snell Roundhand;
  margin: 5px 0 20px 0;
  font-size: 14px;
`;

const Subtitle = styled.p`
  color: #black;
  margin: 0 0 20px 0;
`;

const Time = styled.p`
  margin: 0;
`;

const Description = styled.p`
  max-width: 400px;
`;

const Description2 = styled.p`
  max-width: 400px;
  margin: 0px;
`;

const StyledImage = styled.img`
  width: 90px;
  visibility: visible;
`;

const WeddingDetails: React.FC = () => {
  const { t } = useTranslation();

  const { i18n } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const lang = searchParams.get("lang");

  useEffect(() => {
    window.scrollTo(0, 0);

    if (!lang) {
      searchParams.set("lang", i18n.language);
      setSearchParams(searchParams, { replace: true });
    } else if (lang === "en") {
      i18n.changeLanguage("en");
    } else {
      i18n.changeLanguage("es");
    }
  }, [lang]);
  return (
    <div>
      <Top />
      <div>
        <StyledHeader id="the-wedding">
          {t("tilesOfSections.theWedding").toUpperCase()}
          <div>
            <StyledImage src="https://weddingfy.app/recursos/anillosnegrosfondotrans.gif" />
          </div>
        </StyledHeader>
        <Timeline>
          <TimelineItem>
            <TimelineContentRight>
              <Details>
                <WeddingDate>
                  {t("weddingDayDetails.mehendiDate").toUpperCase()}
                </WeddingDate>
                <WeddingTitle>{t("weddingDayDetails.mehendi")}</WeddingTitle>
                <WeddingLocation>
                  {t("weddingDayDetails.mehendiLocation").toUpperCase()}
                </WeddingLocation>
                {/* <Subtitle>
                  {t("weddingDayDetails.reception").toUpperCase()}
                </Subtitle> */}
                <WeddingAddress>
                  {t("weddingDayDetails.mehendiAddress")}
                </WeddingAddress>
                <WeddingAddress2>
                  {t("weddingDayDetails.mehendiAddress2")}
                </WeddingAddress2>
                <Time>{t("weddingDayDetails.fivepm")}</Time>
                <Description>
                  {t("weddingDayDetails.mehendiDetails")}
                </Description>
                <Description2>
                  {t("weddingDayDetails.mehendiFood")}
                </Description2>
              </Details>
            </TimelineContentRight>
          </TimelineItem>
          <TimelineItem>
            <TimelineContentLeft>
              <Details>
                <WeddingDate>
                  {t("weddingDayDetails.indianCeremonyDate").toUpperCase()}
                </WeddingDate>
                <WeddingTitle>
                  {t("weddingDayDetails.indianCeremony")}
                </WeddingTitle>
                <WeddingLocation>
                  {t("weddingDayDetails.indianCeremonyLocation").toUpperCase()}
                </WeddingLocation>
                <WeddingAddress>
                  {t("weddingDayDetails.mehendiAddress")}
                </WeddingAddress>
                <WeddingAddress2>
                  {t("weddingDayDetails.mehendiAddress2")}
                </WeddingAddress2>
                {/* <Subtitle>
                  {t("weddingDayDetails.ceremony").toUpperCase()}
                </Subtitle> */}
                <Time>{t("weddingDayDetails.eleventhiry")}</Time>
                <Description>
                  {t("weddingDayDetails.indianCeremonyDetails")}
                </Description>
                <Description2>
                  {t("weddingDayDetails.indianCeremonyFood")}
                </Description2>
              </Details>
            </TimelineContentLeft>
          </TimelineItem>
          {/* <TimelineItem>
            <TimelineContentRight>
              <Details>
                <WeddingTitle>
                  {t("weddingDayDetails.indianCelebration")}
                </WeddingTitle>
                <Subtitle>
                  {t("weddingDayDetails.reception").toUpperCase()}
                </Subtitle>
                <Time>{t("weddingDayDetails.sevenpm")}</Time>
                <Description>
                {t("weddingDayDetails.indianReception")}
                </Description>
              </Details>
            </TimelineContentRight>
          </TimelineItem>
          <TimelineItem>
            <TimelineContentLeft>
              <Details>
                <WeddingTitle>
                  {t("weddingDayDetails.beforeWedding")}
                </WeddingTitle>
                <Subtitle>
                  {t("weddingDayDetails.dances").toUpperCase()}
                </Subtitle>
                <Time>{t("weddingDayDetails.eightpm")}</Time>
                <Description>Meet us at this location for dances</Description>
              </Details>
            </TimelineContentLeft>
          </TimelineItem> */}
          <TimelineSubItem>
            <TimelineContentRight>
              <Details>
                <WeddingDate>
                  {t("weddingDayDetails.weddingCeremonyDate").toUpperCase()}
                </WeddingDate>
                <WeddingTitle>
                  {t("weddingDayDetails.catholicCeremony")}
                </WeddingTitle>
                <WeddingLocation>
                  {t("weddingDayDetails.weddingCeremonyLocation").toUpperCase()}
                </WeddingLocation>
                <WeddingAddress>
                  {t("weddingDayDetails.catholicAddress")}
                </WeddingAddress>
                <WeddingAddress2>
                  {t("weddingDayDetails.catholicAddress2")}
                </WeddingAddress2>
                {/* <Subtitle>
                  {t("weddingDayDetails.ceremony").toUpperCase()}
                </Subtitle> */}
                <Time>{t("weddingDayDetails.twopm")}</Time>
                <Description>
                  {t("weddingDayDetails.weddingCeremonyDetails")}
                </Description>
              </Details>
            </TimelineContentRight>
          </TimelineSubItem>
          <TimelineSubItem>
            <TimelineContentLeft>
              <Details>
                <WeddingSubTitle>
                  {t("weddingDayDetails.happyHour")}
                </WeddingSubTitle>
                {/* <Subtitle>
                  {t("weddingDayDetails.happyHour").toUpperCase()}
                </Subtitle> */}
                <WeddingLocation>
                  {t("weddingDayDetails.receptionLocation").toUpperCase()}
                </WeddingLocation>
                <WeddingAddress>
                  {t("weddingDayDetails.adlerAddress")}
                </WeddingAddress>
                <WeddingAddress2>
                  {t("weddingDayDetails.adlerAddress2")}
                </WeddingAddress2>
                <WeddingSubLocation>
                  {t("weddingDayDetails.receptionLocation1")}
                </WeddingSubLocation>
                <Time>{t("weddingDayDetails.sixthiry")}</Time>
                <Description>
                  {t("weddingDayDetails.happyHourDetails")}
                </Description>
              </Details>
            </TimelineContentLeft>
          </TimelineSubItem>
          <TimelineSubItem>
            <TimelineContentRight>
              <Details>
                <WeddingTitle>{t("weddingDayDetails.reception")}</WeddingTitle>
                {/* <Subtitle>
                  {t("weddingDayDetails.reception").toUpperCase()}
                </Subtitle> */}
                <WeddingLocation>
                  {t("weddingDayDetails.receptionLocation").toUpperCase()}
                </WeddingLocation>
                <WeddingAddress>
                  {t("weddingDayDetails.adlerAddress")}
                </WeddingAddress>
                <WeddingAddress2>
                  {t("weddingDayDetails.adlerAddress2")}
                </WeddingAddress2>
                <WeddingSubLocation>
                  {t("weddingDayDetails.receptionLocation2")}
                </WeddingSubLocation>
                <Time>{t("weddingDayDetails.seventhiry")}</Time>
                <Description>
                  {t("weddingDayDetails.receptionDetails")}
                </Description>
              </Details>
            </TimelineContentRight>
          </TimelineSubItem>
        </Timeline>
      </div>
      <Bottom />
    </div>
  );
};

export default WeddingDetails;
