import React, { useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Top from "./Top";
import Bottom from "./Bottom";
import college from "../images/college.png";
import bridge from "../images/bridge.png";
import graduation from "../images/graduation.png";
import DC from "../images/DC.png";
import milo from "../images/milo.png";
import cabo from "../images/cabo.png";
import engagement2 from "../images/engagement2.png";
import museum from "../images/museum.png";
import { useSearchParams } from "react-router-dom";

const Container = styled.div`
  text-align: center;
`;

const StyledHeader = styled.h1`
  display: flex;
  justify-content: center;
  letter-spacing: 10px;
  font-size: 30px;
  margin-bottom: 20px;
  margin-top: 0px;
  padding-top: 100px;
  @media (max-width: 700px) {
    font-size: 15px;
    margin-bottom: 20px;
    padding-top: 80px;
  }
`;

const TheStory = styled.div`
  display: flex;
  margin-bottom: 20px;
  font-size: 20px;
  @media (max-width: 700px) {
    width: auto;
  }
`;

const MainPicture = styled.img`
  width: 80%;
  border-radius: 10px;
`;

const PhotoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
`;

const MainContainer = styled.div`
  padding: 5px 100px;
  @media (max-width: 700px) {
    padding: 5px 30px;
  }
`;

const Story: React.FC = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const lang = searchParams.get("lang");

  useEffect(() => {
    if (!lang) {
      searchParams.set("lang", i18n.language);
      setSearchParams(searchParams, { replace: true });
    } else if (lang === "en") {
      i18n.changeLanguage("en");
    } else {
      i18n.changeLanguage("es");
    }
  }, [lang]);
  return (
    <div>
      <Top />
      <Container>
        <StyledHeader>
          {t("tilesOfSections.ourStory").toUpperCase()}
        </StyledHeader>
        <MainContainer>
          <PhotoContainer>
            <MainPicture src={college} alt="header" />
          </PhotoContainer>
          <TheStory id="the-story">{t("ourStory.bodyOfStory1")}</TheStory>
          <PhotoContainer>
            <MainPicture src={bridge} alt="header" />
          </PhotoContainer>
          <TheStory id="the-story">{t("ourStory.bodyOfStory2")}</TheStory>
          <PhotoContainer>
            <MainPicture src={graduation} alt="header" />
          </PhotoContainer>
          <TheStory id="the-story">{t("ourStory.bodyOfStory3")}</TheStory>
          <PhotoContainer>
            <MainPicture src={DC} alt="header" />
          </PhotoContainer>
          <TheStory id="the-story">{t("ourStory.bodyOfStory4")}</TheStory>
          <PhotoContainer>
            <MainPicture src={milo} alt="header" />
          </PhotoContainer>
          <TheStory id="the-story">{t("ourStory.bodyOfStory5")}</TheStory>
          <PhotoContainer>
            <MainPicture src={cabo} alt="header" />
          </PhotoContainer>
          <TheStory id="the-story">{t("ourStory.bodyOfStory6")}</TheStory>
          <PhotoContainer>
            <MainPicture src={museum} alt="header" />
          </PhotoContainer>
          <TheStory id="the-story">{t("ourStory.bodyOfStory7")}</TheStory>
          <PhotoContainer>
            <MainPicture src={engagement2} alt="header" />
          </PhotoContainer>
        </MainContainer>
      </Container>
      <Bottom />
    </div>
  );
};

export default Story;
