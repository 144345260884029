import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  padding-top: 20px;
  text-align: center;
  @media (max-width: 700px) {
    display: block;
  }
`;
const Text = styled.p`
  font-size: 15px;
  @media (max-width: 700px) {
    display: block;
    font-size: 10px;
  }
`;

const Bottom: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Text>
        &copy; 2024 {t("names.fabiolaAndArjun")} | 03 - 05 {t("names.month")} 2024
      </Text>
      <Text>{t("footer.designed")} ❤️</Text>
    </Container>
  );
};
export default Bottom;
