import React from "react";
import styled from "styled-components";
import homePage2 from "../images/homePage2.png";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const MainPicture = styled.img`
  width: 80%;
  border-radius: 10px;
`;

const PhotoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
`;

const TextContainer = styled.div`
  text-align: center;
  letter-spacing: 7px;
  margin-bottom: 20px;
  font-size: 30px;
  @media (max-width: 700px) {
    font-size: 20px;
  }
`;

const LastTextContainer = styled(TextContainer)`
  margin-bottom: 40px;
  @media (max-width: 700px) {
    margin-bottom: 20px;
  }
`;

const MenuContainer = styled.div`
  margin: 0 200px;
  @media (max-width: 900px) {
    margin-bottom: 20px;
    margin: 0 100px;
  }
`;

const MenuItem = styled.div`
  text-align: center;
  padding: 3px 0;
  font-size: 25px;
  cursor: pointer;
  @media (max-width: 700px) {
    font-size: 15px;
  }
  &:hover {
    transform: scale(1.1);
    border-radius: 10px;
    color: #5f8575;
  }
`;

const Item = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  padding: 7px;
`;

const LastMenuItem = styled(MenuItem)`
  border-bottom: none;
`;

const HomePage: React.FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const handleClick = (url: string) => {
    navigate(url);
  };

  const weddingDate = new Date("09/03/2024 12:1 AM").getTime();
  const todayDate = new Date().getTime();
  const oneDay = 1000 * 60 * 60 * 24;
  const weddingCountDown = Math.ceil((weddingDate - todayDate) / oneDay);

  return (
    <div>
      <TextContainer>
        {t("homePage.countDown", { days: weddingCountDown }).toUpperCase()}
      </TextContainer>
      <PhotoContainer>
        <MainPicture src={homePage2} alt="header" />
      </PhotoContainer>
      <TextContainer>Chicago, IL</TextContainer>
      <LastTextContainer>Adler Planetarium</LastTextContainer>
      <MenuContainer>
        <MenuItem onClick={() => handleClick("/story")}>
          <Item>{t("tilesOfSections.ourStory")}</Item>
        </MenuItem>
        <MenuItem onClick={() => handleClick("/wedding-timeline")}>
          <Item>{t("tilesOfSections.theWedding")}</Item>
        </MenuItem>
        <MenuItem onClick={() => handleClick("/registry")}>
          <Item>{t("tilesOfSections.registryList")}</Item>
        </MenuItem>
        <MenuItem onClick={() => handleClick("/faq")}>
          <Item>{t("tilesOfSections.faq")}</Item>
        </MenuItem>
        <LastMenuItem onClick={() => handleClick("/rsvp")}>
          <Item>{t("tilesOfSections.rSVPSection")}</Item>
        </LastMenuItem>
      </MenuContainer>
    </div>
  );
};

export default HomePage;
